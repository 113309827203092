import React, { useState, useContext } from 'react';
import Card from './Card';
import { LanguageContext } from './LanguageContext'
import './FAQ.css';

const FAQ = () => {
  const { language } = useContext(LanguageContext);
  const [openIndex, setOpenIndex] = useState(null);


  const faqs = {
    en: [
      {
        question: 'What services do you offer?',
        answer: "I offer web development, SEO optimization, social media marketing, and pretty much anything else that is creative in tech. As a child of immigrants, I'm bilingual and bicultural, which allows me to help those who may not speak English. I grew up with parents who didn't, so I fully understand how difficult maneuvering through the world can be when you can't communicate. I'm happy to be able to offer help in both Spanish and English!",
      },
      {
        question: "What experience do you have?",
        answer: "I have a degree in computer science, which gave me ample experience in programming, including front-end and back-end web development. I think this separates me from many other web developers who don't have a technical background. Being able to design and develop a website from scratch is a huge advantage. Many web developers rely on drag and drop editors, which can be limiting. I personally have tried many of these editors and have found that they can be frustrating to work with because of the restrictions. I prefer to code everything by hand so that I have full control over the design and functionality of the website. While in school, I worked on a few projects for local businesses and really enjoyed it. After graduating, I decided to stick with the web development path as a way to help local businesses and myself grow.",
      },
      {
        question: "What's your background?",
        answer: "I'm a first-generation Mexican-American who grew up in little old Bend, Oregon. My parents are hard-working immigrants who came to The US for a better life. Their sacrifices are my driving force, instilling in me a deep sense of responsibility and a desire to give back. I've always wanted to help my community thrive somehow. Building websites as way to grow businesses is my way of giving back. I really believe that having a strong online presence is integral to growing a business. My long-term goal is to help my parents' village in Mexico by funding the schools and educational ventures of their children. I've just always had a yearning to help others and what better way to do that than to start with my own community through tech.",
      },
      {
        question: "How can a website benefit my business?",
        answer: "There are many benefits to having a website for your business. The most important being that it opens you up to a wider audience that you wouldn't be able to reach otherwise. Having a website also gives your business credibility and makes it easier for potential customers to find you. It's a great way to showcase your products and services and provide valuable information to your customers."
      },
      {
        question: 'How can I get started?',
        answer: 'You can get started by contacting me through my contact page, calling or texting my phone number, or reaching out via WhatsApp. All are linked in the footer of this website.',
      },
      {
        question: 'Do you offer support?',
        answer: 'Yes, I offer ongoing support and maintenance for all. I want to make sure that your website is always up and running smoothly.',
      },
      {
        question: "What are your rates?",
        answer: "Rates depend on the project. For websites, there is a small setup fee along with a monthly payment. For SEO and social media marketing, there is a flat monthly fee depending on the services you need. I'm happy to discuss rates with you and find a plan that works for your business, so don't hesitate to reach out :)",
      },
      {
        question: 'How long does it take to build a website?',
        answer: 'The time it takes to build a website depends on the complexity of the project. Simple websites can be built in a few days, while more complex websites can take several weeks.',
      },
      {
        question: 'What is SEO?',
        answer: "SEO stands for Search Engine Optimization. It is the process of optimizing your website to rank higher in search engine results pages. If you don't optimize your website for search engines, you risk losing potential customers to your competitors. It requires a lot of time, work and patience, but the results are very much worth it.",
      },
      {
        question: 'What is social media marketing?',
        answer: "Social media marketing is the process of promoting your business on social media platforms to increase brand awareness, drive traffic, and generate leads for your business. It's a great way to connect with your audience and build relationships with them.",
      },
    ],
    es: [
      {
        question: '¿Qué servicios ofreces?',
        answer: 'Ofrezco desarrollo web, optimización de SEO, marketing en redes sociales y prácticamente cualquier otra cosa que sea creativa en tecnología. Como hija de inmigrantes, soy bilingüe y bicultural. Me permite ayudar a quienes no hablan inglés. Crecí con padres que no lo hablaban, entonces entiendo completamente lo difícil que puede ser moverse por el mundo cuando no puedes comunicarte. ¡Estoy feliz de poder ofrecer ayuda tanto en español como en inglés!',
      },
      {
        question: '¿Qué experiencia tienes?',
        answer: 'Tengo un título en ciencias de la computación, lo que me dio amplia experiencia en programación, incluyendo desarrollo web front-end y back-end. Creo que esto me diferencia de muchos otros desarrolladores web que no tienen un trasfondo técnico. Poder diseñar y desarrollar un sitio web desde cero es una gran ventaja. Muchos desarrolladores web dependen de editores de arrastrar y soltar, lo que puede ser limitante. Personalmente he probado muchos de estos editores y he encontrado que pueden ser frustrantes debido a las restricciones. Prefiero codificar todo a mano para tener control total sobre el diseño y la funcionalidad del sitio web. Mientras estaba en la escuela, trabajé en algunos proyectos para negocios locales y realmente lo disfruté. Después de graduarme, decidí seguir el camino del desarrollo web como una forma de ayudar a los negocios locales y crecer yo misma.',
      },
      {
        question: '¿Cuál es tu trasfondo?',
        answer: 'Soy una mexicana-estadounidense de primera generación que creció en el pequeño Bend, Oregón. Mis padres son inmigrantes trabajadores que vinieron a los EE.UU. en busca de una vida mejor. Sus sacrificios son mi fuerza impulsora, inculcándome un profundo sentido de responsabilidad y un deseo de retribuir. Siempre he querido ayudar a mi comunidad a prosperar de alguna manera. Construir sitios web como una forma de hacer crecer los negocios es mi manera de retribuir. Realmente creo que tener una fuerte presencia en línea es fundamental para hacer crecer un negocio. Mi objetivo a largo plazo es ayudar al pueblo de mis padres en México financiando las escuelas y las iniciativas educativas de sus niños. Siempre he tenido un anhelo de ayudar a los demás y qué mejor manera de hacerlo que comenzando con mi propia comunidad a través de la tecnología.',
      },
      {
        question: '¿Cómo puede una página web beneficiar mi negocio?',
        answer: 'Hay muchos beneficios al tener una página web para tu negocio. El más importante es que te abre a una audiencia más amplia que de otra manera no podrías alcanzar. Tener una página web también le da credibilidad a tu negocio y facilita que los clientes potenciales te encuentren. Es una excelente manera de mostrar tus productos y servicios y proporcionar información valiosa a tus clientes.',
      },
      {
        question: '¿Cómo puedo comenzar?',
        answer: 'Puedes comenzar contactándome a través de mi página de contacto, llamando o enviando un mensaje de texto a mi número de teléfono, o poniéndote en contacto a través de WhatsApp. Todos están enlazados en el pie de página de este sitio web.',
      },
      {
        question: '¿Ofreces soporte?',
        answer: 'Sí, ofrezco soporte y mantenimiento continuo para todos. Quiero asegurarme de que tu sitio web esté siempre en funcionamiento sin problemas.',
      },
      {
        question: '¿Cuáles son tus tarifas?',
        answer: 'Las tarifas dependen del proyecto. Para sitios web, hay una pequeña tarifa de configuración junto con un pago mensual. Para SEO y marketing en redes sociales, hay una tarifa mensual fija dependiendo de los servicios que necesites. Estoy feliz de discutir las tarifas contigo y encontrar un plan que funcione para tu negocio, así que no dudes en ponerte en contacto.',
      },
      {
        question: '¿Cuánto tiempo toma construir un sitio web?',
        answer: 'El tiempo que toma construir un sitio web depende de la complejidad del proyecto. Los sitios web simples pueden construirse en unos pocos días, mientras que los sitios web más complejos pueden tomar varias semanas.',
      },
      {
        question: '¿Qué es SEO?',
        answer: 'SEO significa optimización para motores de búsqueda. Es el proceso de optimizar tu sitio web para que aparezca más alto en las páginas de resultados de los motores de búsqueda. Si no optimizas tu sitio web para los motores de búsqueda, corres el riesgo de perder clientes potenciales frente a tus competidores. Requiere mucho tiempo, trabajo y paciencia, pero los resultados valen mucho la pena.',
      },
      {
        question: '¿Qué es el marketing en redes sociales?',
        answer: 'El marketing en redes sociales es el proceso de promocionar tu negocio en plataformas de redes sociales para aumentar el conocimiento de la marca, generar tráfico y obtener clientes potenciales para tu negocio. Es una excelente manera de conectarte con tu audiencia y construir relaciones con ellos.',
      },
    ],
  };

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h2 className="faq-title">{language === 'en' ? 'Questions?' : 'Preguntas?'}</h2>
      <h3 className="faq-subtitle">{language === 'en' ? 'Here are some answers:' : 'Aquí tengo algunas respuestas:'}</h3>
      
      {faqs[language].map((faq, index) => (
        <Card
          key={index}
          title={faq.question}
          isExpanded={openIndex === index}
          onClick={() => toggleAnswer(index)}
        >
          <div className="faq-answer">{faq.answer}</div>
        </Card>
      ))}

      <div className="more-questions">
        <h2 className="more-questions-title">
          {language === 'en' ? 'More questions?' : '¿Más preguntas?'}
        </h2>
        <h2 className="get-started-title">
          {language === 'en' ? 'Ready to get started?' : '¿Listo para comenzar?'}
        </h2>

        <div className="action-buttons">
          <a href="tel:+15414108786">
            <button className="action-button">
              {language === 'en' ? 'Call or text me' : 'Llámame o mándame un texto'}
            </button>
          </a>
          <a href="/about">
            <button className="action-button">
              {language === 'en' ? 'More information' : 'Mas información'}
            </button>
          </a>
          <a href="mailto:baleria@xochipillidesign.com">
            <button className="action-button">
              {language === 'en' ? 'Send me an email' : 'Mándame un correo electrónico'}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
