import React from 'react';
import './Card.css';

const Card = ({ title, children, isExpanded, onClick }) => {
  return (
    <div className={`custom-card ${isExpanded ? 'card-expanded' : ''}`} onClick={onClick}>
      <div className="card-header">
        <h3>{title}</h3>
        <span className="toggle-icon">{isExpanded ? '▼' : '▲'}</span>
      </div>
      {isExpanded && <div className="card-content">{children}</div>}
    </div>
  );
};

export default Card;
