import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
import './Navbar.css';

const Navbar = () => {
  const { language, toggleLanguage } = useContext(LanguageContext);

  return (
    <nav className="navbar">
      <button onClick={toggleLanguage} className="language-toggle">
        {language === 'es' ? 'english' : 'español'}
      </button>
      
      <div className="nav-content">
        <ul className="nav-links left">
          <li>
            <Link to="/about" className="nav-link">
              {language === 'es' ? 'acerca de' : 'about'}
            </Link>
          </li>
        </ul>

        <Link to="/" className="logo-container">
          <img src="/images/logo.png" alt="Logo" className="logo" />
          <span className="logo-text">Xochipilli Design</span>
        </Link>

        <ul className="nav-links right">
          <li>
            <Link to="/contact" className="nav-link">
              {language === 'es' ? 'contacto' : 'contact'}
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
