import React from 'react';
import './HomePage.css';
import Hero from './Hero';
import FAQ from './FAQ';

const HomePage = () => {
  return (
    <div className="home-page">
      <Hero />
      <FAQ />
    </div>
  );
};

export default HomePage;