import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import './Marquee.css';

const Marquee = ({ text, bgColor, textColor, duration = 5 }) => {
  const [repetitions, setRepetitions] = useState(3);
  
  useEffect(() => {
    const calculateRepetitions = () => {
      setRepetitions(Math.ceil(window.innerWidth / (text.length * 20)));
    };

    calculateRepetitions();
    window.addEventListener('resize', calculateRepetitions);

    return () => window.removeEventListener('resize', calculateRepetitions);
  }, [text]);

  const repeatedText = text.repeat(repetitions);

  return (
    <div className="scrolling-text-banner" style={{ backgroundColor: bgColor }}>
      <div className="scrolling-text-container">
        <motion.div
          className="scrolling-text"
          animate={{ x: [0, `-${100 / repetitions}%`] }}
          transition={{
            duration: duration,
            ease: "linear",
            repeat: Infinity,
          }}
        >
          {[...Array(2)].map((_, index) => (
            <h1 key={index} className="marquee-text" style={{ color: textColor }}>
              {repeatedText}
            </h1>
          ))}
        </motion.div>
      </div>
    </div>
  )
}

export default Marquee;
