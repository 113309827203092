import React, { useState, useContext } from 'react';
import { motion } from 'framer-motion';
import projectData from '../data/projects.json';
import { LanguageContext } from './LanguageContext';
import Marquee from './Marquee';
import ServicesSection from './ServicesSection';
import './AboutPage.css';

const AboutPage = () => {
  const { language } = useContext(LanguageContext);
  const [currentProject, setCurrentProject] = useState(0);

  const nextProject = () => {
    setCurrentProject((prev) => (prev + 1) % projectData.length);
  };

  const prevProject = () => {
    setCurrentProject((prev) => (prev - 1 + projectData.length) % projectData.length);
  };

  return (
    <section className="about-page">

      <div className="banner-image">
        <img
          src="/images/about.png"
          alt="About Banner"
          className="banner-img"
        />
      </div>

      <Marquee 
        text={language === 'en' ? 'about ' : 'acerca de '}
        bgColor="black"
        textColor="#f16f75"
      />

      <h1 className="about-header">
        {language === 'en' ? 'PAST WORK' : 'TRABAJOS PASADOS'}
      </h1>

      <div className="project-carousel">
        <button className="carousel-button prev" onClick={prevProject}>←</button>

        {projectData && projectData.length > 0 && projectData[currentProject] && (
          <motion.img
            key={projectData[currentProject].name}
            src={projectData[currentProject].image}
            alt={projectData[currentProject].name}
            className="project-image"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          />
        )}

        <button className="carousel-button next" onClick={nextProject}>→</button>
      </div>

      <h3 className="project-name">
        {projectData[currentProject] && projectData[currentProject].name}
      </h3>

      <div className="tech-buttons">
        {projectData[currentProject] && projectData[currentProject].technologies.map((tech, index) => (
          <button key={index} className="tech-button">
            {tech}
          </button>
        ))}
      </div>

      <div className="view-site-container">
        <a
          href={projectData[currentProject] && projectData[currentProject].link}
          className="view-site-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          {language === 'en' ? '~ view site ~' : '~ ver sitio ~'}
        </a>
      </div>

      <div>
        <ServicesSection className="about-header"/>
      </div>
    </section>
  )
}

export default AboutPage;
