import { motion } from 'framer-motion';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
import './Hero.css';

const Hero = () => {
  const { language } = useContext(LanguageContext);

  return (
    <section className="hero-section">
      <div className="hero-container">
        <motion.h1
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="hero-title"
        >
          {language === 'es' ? 'Hola,' : 'Hello,'}
        </motion.h1>

        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="hero-subtitle"
        >
          {language === 'es'
            ? '¿Tiene un negocio o una idea que puede beneficiarse de un mayor alcance?'
            : 'Do you have a business or an idea that could benefit from a larger reach?'}
        </motion.h2>

        <div className="hero-buttons">
          <Link to="/about">
            <motion.button
              className="hero-button"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 1, duration: 0.5 }}
            >
              {language === 'es' ? 'Más Información' : 'More Information'}
            </motion.button>
          </Link>

          <Link to="/contact">
            <motion.button
              className="hero-button"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 1.2, duration: 0.5 }}
            >
              {language === 'es' ? 'Contacto' : 'Contact'}
            </motion.button>
          </Link>
        </div>

        <motion.h2
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1.5, duration: 0.5 }}
          className="hero-title"
        >
          {language === 'es' ? '¡Puedo ayudarle!' : 'I can help!'}
        </motion.h2>

        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="hero-subtitle"
        >
          {language === 'es'
            ? '¿Como? Bueno... me especializo en:'
            : 'How? Well... I specialize in:'}
        </motion.h2>

        <div className="hero-cards">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0 }}
            whileHover={{ scale: 1.05 }}
            className="hero-card"
          >
            <img src="/images/laptop.png" alt="Creating websites" className="hero-card-image" />
            <h3 className="hero-card-title">{language === 'es' ? 'crear sitios web' : 'Creating websites'}</h3>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0 }}
            whileHover={{ scale: 1.05 }}
            className="hero-card"
          >
            <img src="images/audience.png" alt="Connecting with your audience" className="hero-card-image" />
            <h3 className="hero-card-title">{language === 'es' ? 'conectarte con tu audiencia' : 'Connecting with your audience'}</h3>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0 }}
            whileHover={{ scale: 1.05 }}
            className="hero-card"
          >
            <img src="images/megaphone.png" alt="Improving online presence" className="hero-card-image" />
            <h3 className="hero-card-title">{language === 'es' ? 'mejorar tu presencia en línea' : 'Improving online presence'}</h3>
          </motion.div>
        </div>

        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2.5, duration: 0.5 }}
          className="hero-subtitle"
        >
          {language === 'es'
            ? 'a través de estrategias creativas de desarrollo web, SEO, y marketing digital.'
            : 'through creative strategies of web development, SEO, and digital marketing.'}
        </motion.h2>
      </div>
    </section>
  );
};

export default Hero;
