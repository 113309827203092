import React, { useContext } from 'react';
import { LanguageContext } from './LanguageContext';
import './Footer.css';

const Footer = () => {
  const { language } = useContext(LanguageContext);

  return (
    <footer className="footer">
      <div className="footer-title">
        {language === 'en' ? 'thanks for looking.' : 'gracias por mirar.'}
      </div>

      <div className="footer-content">
        <div className="footer-section">
          <h3 className="footer-section-title">
            {language === 'en' ? 'EMAIL ME' : 'MÁNDEME UN CORREO ELECTRONICO'}
          </h3>
          <hr className="footer-divider" />
          <a href="mailto:baleria@xochipillidesign.com" className="footer-button">
            baleria@xochipillidesign.com
          </a>
        </div>

        <div className="footer-section">
          <h3 className="footer-section-title">
            {language === 'en' ? 'CALL ME' : 'LLÁMEME'}
          </h3>
          <hr className="footer-divider" />
          <a href="tel:5414108786" className="footer-button">
            (541) 410-8786
          </a>
        </div>
      </div>

      <img
        src="/images/hummingbord-footer2.png"
        alt="hummingbird"
        className="footer-hummingbird"
      />

      <img
        src="/images/ivy.png"
        alt="leaves"
        className="footer-leaves"
      />
    </footer>
  );
};

export default Footer;
